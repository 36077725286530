import "./App.css";
import splashImg from "./splash.png";

function App() {
  const content = (
    <>
      <h1>Ville Vinblad</h1>
      <p className="subtitle">Software Artificer</p>
      <p className="mb-2 mb-md-1">artificer@wijnbladh.com</p>
      <p className="mb-2 mb-md-1">
        <a href="tel:+358449861424">+358 44 986 1424</a>
      </p>
      <p className="mb-2 mb-md-1">
        <a href="https://wijnbladh.com" target="_blank" rel="noreferrer">
          wijnbladh.com
        </a>
      </p>
      <p className="mb-2 mb-md-1">
        <a href="https://github.com/wijnbladh" target="_blank" rel="noreferrer">
          github.com/wijnbladh
        </a>
      </p>
      <p className="mb-2 mb-md-1">
        <a href="https://linkedin.com/in/wijnbladh" target="_blank" rel="noreferrer">
          linkedin.com/in/wijnbladh
        </a>
      </p>
    </>
  );

  return (
    <div className="App justify-content-center align-items-center">
      <div className="container d-md-none">
        <div className="row">
          <div className="col-12 text-center">
            <img className="img-fluid" src={splashImg} alt="Splash"></img>
          </div>
          <div className="col-12 text-center">{content}</div>
        </div>
      </div>
      <div className="container d-none d-md-block">
        <div className="row">
          <div className="col-md-6 d-flex flex-column align-items-end justify-content-center">
            {content}
          </div>
          <div className="col-md-6">
            <img className="img-fluid" src={splashImg} alt="Splash"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
